import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We at Dashbot see SuperBot as a chance to bring a lot of the brilliant people working in the conversational space together so they can learn from each other. When we invite speakers, it’s because we believe that they have unique insights that everyone could benefit from. In that spirit, we want that knowledge to be accessible to everyone, so we recorded everything and collected it all in one convenient place for you. Watch all the presentations from SuperBot 2019 here.`}</p>
    <h2>{`The Future of Ambient Assistance with RAIN CCO Will Hall`}</h2>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/IGqrFYqLyGc?feature=oembed" width="1200"></iframe>
    <h2>{`AI Persona Creation: The Dark Side with Inutit’s Scott Ganz`}</h2>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/XOD6iRYmzos?feature=oembed" width="1200"></iframe>
    <h2>{`Best Kept Secrets to Intelligent Assistants with Twilio’s Nico Acosta`}</h2>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/qDH6ZeACzhM?feature=oembed" width="1200"></iframe>
    <h2>{`Designing Chatbots with IBM Watson with IBM’s Upkar Lidder`}</h2>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/ptXWL4l4yK8?feature=oembed" width="1200"></iframe>
    <h2>{`Reimagining Messaging UI with Slack’s Melissa Greco`}</h2>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/nS07kDnMy3A?feature=oembed" width="1200"></iframe>
    <h2>{`Standardizing Enterprise Solutions with Cognigy’s Derek Roberti`}</h2>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/umBq1RFlitw?feature=oembed" width="1200"></iframe>
    <h2>{`Subscribe to our event mailing list`}</h2>
    <p>{`Thank you to everyone who participated in SuperBot and made it a huge success. We love bringing together people to share and learn from each other, so we regularly host meetups in New York City and San Francisco.`}</p>
    <p>{`If you’d like to hear more and be notified about Dashbot events, sign up for our mailing list here.`}</p>
    <style type="text/css">{`
	#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
	/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
    #mc_embed_signup .button {
        background: #ff6a51;
    }
    #mc_embed_signup .button:hover {
        background: #30aabd;
    }
`}
    </style><form action="https://dashbot.us13.list-manage.com/subscribe/post?u=a7c5d5ea7f4d59ddd76ed312c&id=e5438a1680" id="mc-embedded-subscribe-form" method="post" name="mc-embedded-subscribe-form" noValidate="" target="_blank">
      <p>{`*`}{` indicates required`}</p>
      <label htmlFor="mce-EMAIL">Email Address \*</label>
      <input id="mce-EMAIL" name="EMAIL" type="email" value=""></input>
      <label htmlFor="mce-FNAME">First Name \*</label>
      <input id="mce-FNAME" name="FNAME" type="text" value=""></input>
      <label htmlFor="mce-LNAME">Last Name \*</label>
      <input id="mce-LNAME" name="LNAME" type="text" value=""></input>
      <label htmlFor="mce-MMERGE3">Job Title </label>
      <input id="mce-MMERGE3" name="MMERGE3" type="text" value=""></input>
      <label htmlFor="mce-MMERGE4">Company </label>
      <input id="mce-MMERGE4" name="MMERGE4" type="text" value=""></input>
**Event Locations**
      <ul>
        <li parentName="ul">
          <input id="mce-group[12769]-12769-0" name="group[12769][1]" type="checkbox" value="1"></input>
          <label htmlFor="mce-group[12769]-12769-0">San Francisco</label>
        </li>
        <li parentName="ul">
          <input id="mce-group[12769]-12769-1" name="group[12769][2]" type="checkbox" value="2"></input>
          <label htmlFor="mce-group[12769]-12769-1">New York</label>
        </li>
        <li parentName="ul">
          <input id="mce-group[12769]-12769-2" name="group[12769][4]" type="checkbox" value="4"></input>
          <label htmlFor="mce-group[12769]-12769-2">Superbot</label>
        </li>
      </ul>
      <input name="b_a7c5d5ea7f4d59ddd76ed312c_e5438a1680" tabIndex="-1" type="text" value=""></input>
      <input id="mc-embedded-subscribe" name="subscribe" type="submit" value="Subscribe"></input>
    </form>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      